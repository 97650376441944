<template>
  <div class="view-open-mod">
    <CompHeader :title="ItemInfo.name" />
    <div class="swiper-box" v-if="!iscdk">
      <Swiper />
    </div>
    <div class="main-box">
      <div class="good-title">
        {{ ItemInfo.name }}
      </div>
      <div class="center-box">
        <div class="float-black-box" v-show="luckdrawShow"></div>
        <div class="good-img">
          <goods
            class="rotate-goods-box"
            :pic="ItemInfo.weapon_cover"
            :cover="ItemInfo.cover"
            v-show="luckdrawShow == false"
          ></goods>
          <template v-if="active > 5">
            <luckdrawY
              :ItemData="ItemData"
              :givingItemData="givingItemData"
              :CSnum="CSnum"
              :active="active"
              :List="List"
              :Animation_checked="Animation_checked"
              @zhongjiang="GetUnpackingRecord"
              v-show="luckdrawShow"
              @fromChild="fromChild"
            ></luckdrawY>
          </template>
          <template v-else>
            <luckdrawX
              :ItemData="ItemData"
              :givingItemData="givingItemData"
              :CSnum="CSnum"
              :active="active"
              :List="List"
              :Animation_checked="Animation_checked"
              v-show="luckdrawShow"
              @zhongjiang="GetUnpackingRecord"
              @fromChild="fromChild"
              :isCdkeyBox="iscdk"
            >
            </luckdrawX>
          </template>
        </div>
      </div>
      <div v-if="iscdk" class="iscdkbox">
        <div class="input">
          <input
            class="inputbox"
            v-model.trim="cdkvalue"
            type="text"
            placeholder="请输入CDK"
          />
        </div>
        <div class="open-btn-box">
          <div class="open-btn" @click="Open">
            <p>打开</p>
          </div>
          <div
            class="fun-list-btn"
            :class="{ 'fun-list-btn-active': exchange == 1 }"
            @click="closeAnimation"
          >
            跳过动画
          </div>
        </div>
      </div>
      <div v-else>
        <div class="num-box">
          <div
            v-for="item in [1, 2, 3, 4, 5]"
            :key="item"
            @click="active = item"
            class="num-box-item point"
            :class="{ 'num-box-item-active': active === item }"
          >
            X{{ item }}
          </div>
        </div>
        <div class="open-btn-box">
          <div @click="Open" class="open-btn flex-c point">
            <img
              class="m-icon"
              src="../../assets/newImages/coin-d.png"
              alt=""
            />
            <p>{{ (ItemInfo.bean * active).toFixed(2) }}</p>
            <div style="margin-left: 0.06rem">打开</div>
          </div>
          <div
            class="fun-list-btn"
            :class="{ 'fun-list-btn-active': exchange == 1 }"
            @click="closeAnimation"
          >
            跳过动画
          </div>
        </div>
      </div>
      <div class="odd-box">
        <div class="odds-box">
          <div class="odds">
            <div
              class="odd-item"
              :class="`odd-item${item.level - 1}`"
              v-for="(item, index) in ItemInfo.odds_list"
              :key="index"
            >
              {{ item.odds }}
            </div>
          </div>
        </div>
      </div>
      <goods-main
        :DetailList="List"
        :id="box_id"
        :isCdkeyBox="iscdk"
      ></goods-main>
    </div>

    <!-- 开奖遮罩层 -->
    <van-overlay @mousewheel.prevent.native="() => {}" :show="Tshow">
      <div :class="active == 5 ? 'wrappery' : 'wrapperx'">
        <div class="Show_box">
          <AOpenBox
            v-if="Tshow"
            :imgList="ItemShuJv"
            @cancelFun="Postcash"
            @confirmFun="GuanBi"
          />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  Box_detail,
  Open,
  Cash,
  PersonalInfo,
  BoxUnpackingRecord,
  getTypedata,
  getStatisticsData,
  WelfareOpen,
} from "@/network/api.js";
import { mapState, mapMutations } from "vuex";
import goodsMain from "@/components/PubOpen/Goods.vue";
import goods from "./component/goods_box.vue";
import luckdrawY from "./component/luckdrawY";
import luckdrawX from "./component/luckdrawX.vue";
import get from "./component/get.vue";
import performance from "./component/performance.vue";
import game from "./component/game.vue";
import {
  proBList,
  tableData,
  tableRecordList,
  statisticsData,
  statisticsList,
  typeData,
  typeList,
} from "./index.js";
import record from "@/components/newtable/index.vue";
import record2 from "./component/record.vue";
import AOpenBox from "@/components/singleBox/AOpenBoxNew.vue";
import Swiper from "./component/Swiper.vue";
// import leftFloat from "@/components/left-float/new.vue";
// import NewTableVue from '../NewUser/components/NewTable.vue';
export default {
  name: "Activity_open",
  props: {
    itemsinfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    CDKtype: {
      type: String && Number,
    },
    iscdk: {
      type: Boolean,
      default: false,
    },
    cdkboxid: {
      type: String && Number,
    },
  },
  data() {
    return {
      // tabList: ["物品列表", "历史掉落", '十佳掉落3'],
      tabList: ["物品列表", "历史掉落"],
      current: 0,
      box_type: "", //宝箱type
      ItemInfo: {}, //宝箱信息(不完整)
      ItemData: [], //奖品信息
      givingItemData: [], //弹药开箱赠送的奖品
      ItemShuJv: [], //奖品信息
      List: [], //宝箱内物品
      luckdrawShow: false, //开奖列表显示
      show: false, //CDK遮罩层
      CDK_Data: "", //CDK
      CDK_Show: false, //按钮替换
      ItemData_CDK: {}, //CDk宝箱信息
      cdkvalue: "",
      active: 1,
      CSnum: 0,
      Animation_checked: true,
      Tshow: false,
      JiaZhi: 0,
      KeyArr: [1, 2, 3, 4, 5],
      disabled: false,
      HistoryList: [],
      left: "-5.52",
      time: null,
      time_Set_Box_detail: null,
      // proList: ["0.8", "4.2", "50", "45"],
      proBList: proBList,
      noticeStatus: true,
      tableData: {},
      tableRecordList,
      statisticsData,
      statisticsList,
      typeData,
      typeList,
      recordList: [],
      playerShow: false, // 玩家掉落点击弹窗
      popupTab: ["最近获得", "盲盒游戏", "近期表现"],
      tabCurrent: 0,
      getList: [],
      performanceList: [],
      gameList: [],
      loading: true,
      activeTab: 0,
      typenum: 100, //类型统计的num
      nums: 1, //概率对比的数据
      statnum: 100, //掉落数据chage
      box_id: "",
      exchange: null, //跳过动画
    };
  },
  created() {
    this.ChangeAudioStatus(true);
    this.box_type = this.$route.query.type;
    // this.Box_detail();
    this.GetUnpackingRecord();
    this.Box_detaildata();
    if (this.iscdk) {
      this.box_id = this.cdkboxid;
    } else {
      this.box_id = this.$route.query.id;
    }
    // this.Set_Box_detail()
  },
  computed: {
    ...mapState(["soundStatus", "user"]),
    ShowOrHide() {
      return "left:" + this.left + "rem";
    },
  },
  watch: {
    itemsinfo(nv) {
      this.ItemInfo = this.itemsinfo;
      this.List = (this.ItemInfo.contains || []).map((item) => {
        return {
          lv: item.level,
          ...item,
          ...item.skins,
        };
      });
    },
    cdkboxid(nv) {
      this.box_id = nv;
    },
  },
  methods: {
    ...mapMutations(["ChangeAudioStatus", "User"]),
    tabCurrentChange(i) {
      this.tabCurrent = i;
      if (i === 0) {
        setTimeout(() => {
          this.getList = [
            {
              title: "盲盒对战15.96 > 25.39",
              name: "P250(纪念品)I天旱（久经沙场）",
              time: "九天揽月3 2023-07-11 20:20:20 ",
              price: "5.55",
            },
          ];
        }, 2000);
      } else if (i === 1) {
        setTimeout(() => {
          this.gameList = [
            {
              type: 1,
              title: "P250(纪念品)I天旱（久经沙场）",
              time: "2023-07-11 20:20:20 ",
              price: "5.55",
              boxPrice: 1.22,
              num: 1,
            },
            {
              type: 1,
              title: "P250(纪念品)I天旱（久经沙场）",
              time: "2023-07-11 20:20:20 ",
              price: "5.55",
              boxPrice: 1.22,
              num: 1,
            },
            {
              type: 1,
              title: "P250(纪念品)I天旱（久经沙场）",
              time: "2023-07-11 20:20:20 ",
              price: "5.55",
              boxPrice: 1.22,
              num: 1,
            },
            {
              type: 1,
              title: "P250(纪念品)I天旱（久经沙场）",
              time: "2023-07-11 20:20:20 ",
              price: "5.55",
              boxPrice: 1.22,
              num: 1,
            },
          ];
        }, 2000);
      } else {
        this.performanceList = [
          {
            goodImg:
              "https://steam.skinsimage.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17PLfYQJK9cyzhr-JkvbnJ4Tck29Y_chOhujT8om7i1bh_hE4amr7dYTEe1U6NFqBqQfvxe-50ZO86s6azXMxvCQlsymJzgv330_aLijZVA",
            name: "P250(纪念品)",
          },
        ];
      }
    },
    // 跳过动画
    closeAnimation() {
      if (this.exchange == 1) {
        this.exchange = null;
      } else {
        this.exchange = 1;
      }
      this.Animation_checked = !this.Animation_checked;
      this.ChangeAudioStatus(this.Animation_checked);
    },
    onChange(v) {
      // 进度条的方法
    },
    goBack() {
      this.$router.go(-1);
    },
    currentChange(i) {
      this.current = i;
      if (i == 2) {
        this.getStatData();
      }
      if (i == 3) {
        this.gettypedatalist();
      }
    },
    // 历史掉落分页change
    listchange(v) {},
    // 获取type类型数据
    numchange(i) {
      this.typenum = i;
      this.gettypedatalist(i);
    },
    gettypedatalist(num = 100) {
      let box_id = this.$route.query.id;
      getTypedata(box_id, num).then((res) => {
        let data = res.data.data;
        this.typeData = {
          list: data.map((v) => {
            return {
              type: v.level,
              theory: v.odds,
              openNum: v.count,
              currently: v.out_odds,
              typenum: this.typenum,
            };
          }),
        };
      });
    },
    // 选中掉落记录的change
    numchangestate(i) {
      this.statnum = i;
      this.getStatData(i);
    },
    // 获得掉落记录数据
    getStatData(num = 100) {
      let box_id = this.$route.query.id;
      getStatisticsData(box_id, num).then((res) => {
        let data = res.data.data;
        this.statisticsData = {
          list: data.map((v) => {
            return {
              get: v.skins.cover,
              num: v.up_count,
              total: v.total_count,
              name: v.skins.name,
              cover: v.skins.cover,
              dura_alias: v.skins.dura_alias,
              statnum: this.statnum,
            };
          }),
        };
      });
    },

    Box_detaildata() {
      if (this.iscdk) {
        this.ItemInfo = this.itemsinfo;
        this.List = (this.ItemInfo.contains || []).map((item) => {
          return {
            lv: item.level,
            ...item,
            ...item.skins,
          };
        });
      } else {
        Box_detail(this.$route.query.id).then((res) => {
          this.ItemInfo = res.data.data;
          this.List = (res.data?.data?.contains || []).map((item) => {
            return {
              lv: item.level,
              ...item,
              ...item.skins,
            };
          });
        });
      }
    },

    Open_History() {
      if (this.left == "-2.5") {
        this.left = "-5.52";
      } else if (this.left == "-5.52") {
        this.left = "-2.5";
      }
    },
    dropItemClick(type = 0) {
      this.activeTab = type;
      this.GetUnpackingRecord();
    },
    // 获得历史掉落记录
    GetUnpackingRecord(funParams = {}) {
      let params = {
        id: this.$route.query.id,
        type_id: 1,
      };
      params = { ...params, ...funParams };
      BoxUnpackingRecord(params).then((res) => {
        this.HistoryList = res.data.data;
        // this.tableData = {
        //   total: this.HistoryList.length,
        //   pageSize: 20,
        //   list: this.HistoryList.map((item) => {
        //     return {
        //       ...item,
        //       height: "0.5rem",
        //     };
        //   }),
        // };
      });
    },
    //关闭CDK弹框
    GBCDK() {
      this.show = false;
      this.CDK_Data = "";
    },
    //开箱
    Open() {
      if (!this?.user?.id) {
        this.$message({
          message: "请先登录",
          type: "error",
        });
        return false;
      }
      if (this.disabled) {
        return false;
      }
      this.luckdrawShow = true;
      this.disabled = true;
      if (this.iscdk) {
        if (!this.cdkvalue) {
          this.$message({
            message: "请输入cdk",
            type: "error",
          });
          this.disabled = false;
          this.luckdrawShow = false;
          return;
        }
        let params = {
          id: this.CDKtype,
          cdk: this.cdkvalue,
        };
        WelfareOpen(params).then((res) => {
          if (res.data.code == 200) {
            this.ItemData = res.data.data;
            this.CSnum += 1;
            this.GetPersonalInfo();
            this.ZhongJiang();
            this.Select_All();
            this.cdkvalue = "";
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
            this.disabled = false;
            this.luckdrawShow = false;
            this.GetPersonalInfo();
          }
        });
      } else {
        Open(this.$route.query.id, this.active).then((res) => {
          if (res.data.code == 200) {
            this.ItemData = res.data.data;
            this.CSnum += 1;
            this.GetPersonalInfo();
            this.ZhongJiang();
            this.Select_All();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
            this.disabled = false;
            this.luckdrawShow = false;
            this.GetPersonalInfo();
          }
        });
      }
    },

    //默认全部选中
    Select_All() {
      for (let i = 0; i < this.ItemShuJv.length; i++) {
        let key = "Select";
        let value = true;
        this.ItemShuJv[i][key] = value;
        setTimeout(() => {
          this.$refs.Select[i].style.border = "1px solid #E9B10E";
        }, 10);
      }
      this.TotalPriceCal();
    },

    //选中
    SelectItem(item, index) {
      if (item.Select) {
        delete item.Select;
        this.$refs.Select[index].style.border = "1px solid transparent";
      } else {
        let key = "Select";
        let value = true;
        item[key] = value;
        this.$refs.Select[index].style.border = "1px solid #E9B10E";
      }
      this.TotalPriceCal();
    },

    // 计算回收总价
    TotalPriceCal() {
      this.JiaZhi = 0;
      let A_Arr = this.ItemShuJv.filter((v) => {
        return v.Select == true;
      });

      for (let k = 0; k < A_Arr.length; k++) {
        this.JiaZhi += Number(A_Arr[k].bean);
      }
    },

    fromChild(v) {
      this.disabled = false;
      this.luckdrawShow = false;
    },

    //中奖
    ZhongJiang() {
      for (let j = 0; j < this.ItemShuJv.length; j++) {
        let key = "Select";
        let value = true;
        this.ItemShuJv[j][key] = value;
        this.JiaZhi = 0;
        for (let i = 0; i < this.ItemShuJv.length; i++) {
          this.JiaZhi = this.JiaZhi + Number(this.ItemShuJv[i].bean);
        }
        this.Tshow = true;
        this.GetUnpackingRecord();
      }
    },

    //关闭遮罩层
    GuanBi() {
      this.disabled = false;
      this.Tshow = false;
    },
    //筛选
    IsCover(item, index) {
      if (this.ItemShuJv[index].Select == true) {
        this.ItemShuJv[index].Select = false;
      } else {
        this.ItemShuJv[index].Select = true;
      }
      this.KeyArr = this.KeyArr.map((item) => {
        return item + 1;
      });

      this.JiaZhi = 0;

      let ZJArr = this.ItemShuJv.filter((i) => {
        return i.Select == true;
      });
      for (let i = 0; i < ZJArr.length; i++) {
        this.JiaZhi = this.JiaZhi + Number(ZJArr[i].bean);
      }
    },
    //回收
    Postcash(chooseList) {
      console.log("回收");
      if (!chooseList || !chooseList.length) {
        this.$message({
          message: "请选择要回收的物品",
          type: "warning",
        });
        return false;
      }

      Cash(chooseList).then((res) => {
        this.$message({
          message: "回收成功",
          type: "success",
        });
        this.GetPersonalInfo();
        this.GuanBi();
      });
    },

    //切换组件与按钮
    childByValue(val) {
      this.luckdrawShow = val;
      this.CDK_Show = val;
      this.CDK_Data = "";
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },
  },

  beforeRouteLeave: function (to, from, next) {
    // 关闭页面的时候执行关闭这个弹窗
    this.GuanBi();
    // 同样隐藏这个开奖的动画 - 不会造成金币缺少的时候打不开箱子的问题
    this.luckdrawShow = false;

    clearInterval(this.time);
    clearInterval(this.time_Set_Box_detail);
    this.time = null;

    next();
  },

  components: {
    goods,
    luckdrawY,
    goodsMain,
    luckdrawX,
    record,
    record2,
    performance,
    get,
    game,
    AOpenBox,
    Swiper,
    // NewTableVue
  },
};
</script>

<style scoped lang="scss" scoped>
.black-box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 396;
  background: rgba(0, 0, 0, 0.8);
}

.view-open-mod {
  width: 100%;
  // padding: 0 0.2rem;
  //遮罩层定位
  position: relative;

  .swiper-box {
    overflow: hidden;
    padding-top: 0.18rem;
  }
  .main-box {
    width: 3.35rem;
    margin: 0 auto;
    // padding: 0 0.2rem
  }
  .good-title {
    width: 100vw;
    // height: 0.6rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -0.2rem;
  }
  .center-box {
    width: 3.5rem;
    height: 1.6rem;
    margin: 0 auto;
    margin-bottom: 0.18rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -0.2rem;
    .float-black-box {
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.85);
      z-index: 67;
    }
    .good-img {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.1rem;
    }
  }
  .num-box {
    width: 100%;
    padding: 0 0.24rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.12rem;
    position: relative;
    .num-box-item {
      width: 1.38rem;
      height: 0.26rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.1rem;
      border: 0.01rem solid #69552d;
      margin-right: 0.12rem;
    }
    .num-box-item-active {
      background-color: #817043;
    }
  }
  .iscdkbox {
    margin: 0.12rem 0;
    .input {
      width: 100%;
      display: flex;
      justify-content: center;
      .inputbox {
        width: 2.75rem;
        height: 0.4rem;
        background: rgba(29, 19, 0, 0.6);
        border: 0.01rem solid #372e1d;
        text-align: center;
        font-weight: 400;
        font-size: 0.14rem;
        color: #ffedd1;
      }
    }
    .open-btn {
      width: 1.83rem;
      height: 0.34rem;
      font-size: 0.14rem;
      background: url(../../assets/newImages/btn-active-bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #020202;
      margin: 0.12rem auto;
      p {
        font-size: 0.14rem;
        color: #020202;
        font-weight: 600;
      }
    }
  }

  .open-btn-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.18rem;
    position: relative;
    .open-btn {
      width: 1.83rem;
      height: 0.34rem;
      font-size: 0.14rem;
      background: url(../../assets/newImages/btn-active-bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #020202;
      p {
        font-size: 0.14rem;
        color: #020202;
        font-weight: 600;
      }
    }
    .m-icon {
      width: 0.19rem;
      height: 0.19rem;
      border-radius: 50%;
      box-shadow: 0rem 0.01rem 0.04rem 0rem rgba(127, 72, 10, 0.8);
      margin-right: 0.02rem;
    }
    .fun-list-btn {
      position: absolute;
      right: 0.06rem;
      width: 0.62rem;
      height: 0.26rem;
      line-height: 0.26rem;
      text-align: center;
      font-size: 0.12rem;
      background: url(../../assets/newImages/blind-btn-bg.png) no-repeat center;
      background-size: 100% 100%;
    }
    .fun-list-btn-active {
      @include bgImg("dust-mall-actypeitembg");
    }
  }
  .notice-box-wrap {
    margin-bottom: 0.18rem;
    .notice-box {
      width: 100%;
      height: 0.3rem;
      padding: 0.05rem 0.26rem;
      font-size: 0.1rem;
      color: #585896;
      position: relative;
      background: url(../../assets/images/new/bg9.png) no-repeat center;
      background-size: 100% 100%;
      .notice-icon {
        position: absolute;
        left: 0.09rem;
        top: 0.09rem;
        width: 0.12rem;
        height: 0.12rem;
        background: url(../../assets/images/new/bg6.png) no-repeat center;
        background-size: 100% 100%;
      }
      .close-icon {
        position: absolute;
        right: 0.12rem;
        top: 0.11rem;
        width: 0.08rem;
        height: 0.08rem;
        background: url(../../assets/images/new/bg7.png) no-repeat center;
        background-size: 100% 100%;
      }
      .text {
        .van-notice-bar {
          background-color: unset;
          color: #585896;
          line-height: 0.2rem;
          height: 0.2rem;
        }
      }
    }
  }
  .odd-box {
    margin-bottom: 0.12rem;
    .odds-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .odds {
        display: flex;
        align-items: center;
        .odd-item {
          margin-right: 0.19rem;
          font-size: 0.1rem;
          padding-left: 0.06rem;
          min-width: 0.33rem;
          height: 0.19rem;
          line-height: 0.19rem;
        }
        .odd-item0 {
          background: url(../../assets/newImages/odd0.png) no-repeat;
          background-size: 100% 100%;
        }
        .odd-item1 {
          background: url(../../assets/newImages/odd1.png) no-repeat;
          background-size: 100% 100%;
        }
        .odd-item2 {
          background: url(../../assets/newImages/odd2.png) no-repeat;
          background-size: 100% 100%;
        }
        .odd-item3 {
          background: url(../../assets/newImages/odd3.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .title-box {
    padding: 0.38rem 1.93rem 0;
    margin-bottom: 0.54rem;
    .title {
      width: 100%;
      height: 0.83rem;
      background: url(../../assets/images/OpenBox/title-bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 0.1rem;
      font-weight: 500;
    }
  }

  &-title {
    position: relative;
  }

  .Box_History {
    width: 3rem;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    transition: 0.3s;

    .An_btn {
      position: absolute;
      right: -0.4rem;
      top: 3rem;
      width: 0.4rem;
      height: 1.3rem;
      writing-mode: vertical-rl;
      background: #ffd300;
      text-align: center;
      line-height: 0.45rem;
      letter-spacing: 4px;
      font-size: 0.12rem;
      border-radius: 0 0.04rem 0.04rem 0;
      color: #000;
      cursor: pointer;
    }

    .Box_History_title {
      width: 3rem;
      height: 0.7rem;
      text-align: center;
      line-height: 0.7rem;
      font-size: 0.12rem;
    }

    .Box_History_item {
      width: 3rem;
      height: 1.1rem;
      box-sizing: border-box;
      display: flex;
      cursor: pointer;
      position: relative;

      .D_BJ {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: 1;
        top: 0;
      }

      .ceng {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -3rem;
        top: 0;
        background: rgba($color: #000000, $alpha: 0.7);
        display: flex;
        align-items: center;
        border: 2px solid #ffd300;
        box-sizing: border-box;
        z-index: 11;
        transition: 0.3s;
        border-radius: 0.06rem;

        .pic {
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 50%;
          overflow: hidden;
          margin-left: 0.2rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: 0.1rem;
          color: #fff;
          margin-left: 0.2rem;
        }
      }

      .pic {
        width: 1.1rem;
        height: 1.1rem;
        margin-left: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;

        .pic_bj {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 80%;
            max-height: 80%;
          }
        }

        img {
          position: relative;
          z-index: 10;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .Box_info {
        width: 1.7rem;
        height: 1.1rem;
        margin-left: 0.2rem;
        position: relative;
        z-index: 2;

        p {
          width: 100%;
          height: 0.4rem;
          margin-top: 0.2rem;
          line-height: 0.4rem;
          font-size: 0.1rem;
          color: #fff;
        }

        span {
          font-size: 0.1rem;
          color: #d4d4d4;
        }
      }

      &:hover {
        .ceng {
          left: 0;
        }
      }
    }
  }

  .Dh_box {
    position: absolute;
    right: 0.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.45rem;
    font-size: 0.1rem;
    font-weight: 400;
    color: #3399cc;

    .dhbtn {
      cursor: pointer;

      img {
        display: block;
        width: 1.5rem;
        height: 0.5rem;
      }
    }
  }

  .back {
    position: absolute;
    right: 1.2rem;
    top: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.1rem;
    font-weight: 400;
    cursor: pointer;
  }

  .game-info {
    position: absolute;
    top: 6.8rem;
    left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.11rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3399cc;
    cursor: pointer;

    img {
      width: 0.21rem;
      height: 0.21rem;
    }
  }

  .proB-body {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.21rem;
    position: relative;
    flex-wrap: wrap;
  }

  .el-button {
    display: block;
    margin: rem(10) auto rem(30);
    height: 0.73rem;
    font-size: 0.1rem;
    border-radius: 0;
    background: none;
    border-style: none;
    transition: all 0.15s ease-in-out 0s;
    padding: 0;
    overflow: hidden;

    span {
      display: block;
      width: 100%;
      height: 0.73rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #e9b10e;
      padding-left: 0.4rem;
      box-sizing: border-box;

      img {
        width: 0.4rem;
      }

      .Yuan {
        width: 4.76rem;
        height: 0.73rem;
        background-image: url("../../assets/images/OpenBox/open-btn.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: 0.73rem;
        font-size: 0.1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #228ec8;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .el-button.is-loading:before {
    pointer-events: none;
    content: "";
    position: absolute;
    left: -0.01rem;
    top: -0.01rem;
    right: -0.01rem;
    bottom: -0.01rem;
    border-radius: inherit;
    background: rgba(255, 2, 17, 0.2);
  }

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: none !important;
    border-color: #ffd300 !important;
  }
  .wrapperx {
    position: absolute;
    z-index: 110;
    left: 0rem;
    top: -20%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .Show_box {
      // width: 3.35rem;
      height: 4.86rem;
      .Show_title {
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .wrappery {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .Show_box {
      width: 3.35rem;
      height: 3.2rem;
      display: flex;
      flex-wrap: wrap;
      .Show_title {
        text-align: center;
        font-size: 12px;
      }
      .a-open-box-new {
        padding-top: 0.22rem;
      }
    }
  }
  .showbox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .itembox {
      width: 3.6rem;
      border: 0.01rem solid #5d5d9b;
      background: #08081e;
      .title {
        width: 100%;
        height: 0.38rem;
        text-align: center;
        line-height: 0.38rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.16rem;
        color: #dfdbff;
        position: relative;
        img {
          position: absolute;
          right: 0.2rem;
          top: 0.1rem;
          width: 0.12rem;
        }
      }
      .line {
        width: 100%;
        border: 0.01rem solid;
        border-image: linear-gradient(
            90deg,
            rgba(93, 93, 155, 0),
            rgba(93, 93, 155, 1),
            rgba(93, 93, 155, 0)
          )
          1 1;
      }
    }
    .tablebox {
      width: 3.5rem;
      margin: 0.1rem auto;
      height: 5.3rem;
      overflow-y: scroll;
      background: linear-gradient(90deg, rgba(8, 8, 30, 0) 0%, #08081e 72%);
      .header-img {
        width: 0.3rem;
        height: 0.3rem;
      }
      .username {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.1rem;
        color: #cccccc;
      }
    }
  }
  .overlay-wrapperx {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .Show_box {
      width: 3.35rem;
      height: 4.27rem;
      .Show_title {
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .overlay-wrappery {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .Show_box {
      width: 3.35rem;
      height: 3.2rem;
      display: flex;
      flex-wrap: wrap;
      .Show_title {
        text-align: center;
        font-size: 12px;
      }
      .a-open-box-new {
        padding-top: 0.22rem;
      }
    }
  }
  .overlay-showbox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .itembox {
      width: 3.6rem;
      border: 0.01rem solid #5d5d9b;
      background: #08081e;
      position: relative;
      .rest-img {
        width: 0.2rem;
        height: 0.2rem;
        position: absolute;
        top: 0.08rem;
        left: 0.3rem;
        background: url(../../assets/newImages/rest.png) no-repeat;
        background-size: 100% 100%;
      }
      .title {
        width: 100%;
        height: 0.38rem;
        text-align: center;
        line-height: 0.38rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.16rem;
        color: #dfdbff;
        position: relative;
        img {
          position: absolute;
          right: 0.2rem;
          top: 0.1rem;
          width: 0.12rem;
        }
      }
      .line {
        width: 100%;
        border: 0.01rem solid;
        border-image: linear-gradient(
            90deg,
            rgba(93, 93, 155, 0),
            rgba(93, 93, 155, 1),
            rgba(93, 93, 155, 0)
          )
          1 1;
      }
    }
    .tablebox {
      width: 3.5rem;
      margin: 0.1rem auto;
      height: 5.3rem;
      overflow-y: scroll;
      background: linear-gradient(90deg, rgba(8, 8, 30, 0) 0%, #08081e 72%);
      .header-img {
        width: 0.3rem;
        height: 0.3rem;
      }
      .username {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.1rem;
        color: #cccccc;
      }
      .colorbox {
        width: 0.24rem;
        height: 0.1rem;
      }
      .c1 {
        background: #ff9902;
      }
      .c2 {
        background: #cc0000;
      }
      .c3 {
        background: #660099;
      }
      .c4 {
        background: #003399;
      }
    }
  }
  .overlay-my-backpack {
    width: 3.37rem;
    min-height: 5.8rem;
    background: #08081e;
    border: 0.01rem solid #5d5d9b;
    // background: pink;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0.08rem 0.11rem 0.12rem 0.12rem;
    .close-btn {
      position: absolute;
      right: 0.12rem;
      top: 0.12rem;
      width: 0.12rem;
      height: 0.12rem;
      background: url(../../assets/newImages/close-btn.png) no-repeat;
      background-size: 100% 100%;
    }
    .title {
      text-align: center;
      margin-bottom: 0.2rem;
      font-weight: 600;
      color: #dfdbff;
      line-height: 0.22rem;
    }
    .bg-line {
      width: 3.25rem;
      height: 0.02rem;
      background: url(../../assets/newImages/line.png) no-repeat;
      background-size: 100% 100%;
      left: 0.05rem;
      top: 0.38rem;
      position: absolute;
    }
    .bg-line2 {
      top: unset;
      bottom: 0.5rem;
    }
    .animation-bg {
      width: 100%;
      padding: 0.2rem 0rem;
      margin-bottom: 0.12rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .animation-bg-box {
        width: 0.68rem;
        height: 0.68rem;
        // background: pink;
      }
    }
    .backpack-box {
      width: 100%;
      // height: 4.26rem;
      min-height: 3rem;
      //   border: 0.02rem solid #2f2f5e;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow-y: auto;
      margin-bottom: 0.18rem;
      align-content: flex-start;
      .backpack-item {
        width: 1rem;
        height: 1.02rem;
        margin-right: 0.06rem;
        margin-bottom: 0.06rem;
        flex-shrink: 0;
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
    .page-box {
      width: 100%;
      height: 0.18rem;
      //   background: pink;
      margin-bottom: 0.3rem;
    }
    .bottom-btn-box {
      width: 100%;
      position: relative;
      .btn-box {
        background: linear-gradient(135deg, #242448 0%, #42426d 100%);
        border-radius: 0.02rem;
        border: 0.01rem solid #5a5a9a;
        padding: 0.03rem 0.32rem;
        font-size: 0.14rem;
        line-height: 0.2rem;
        width: fit-content;
      }
      .choose-all-box {
        position: absolute;
        top: 0;
        right: 0.1rem;
        height: 0.26rem;
        font-size: 0.12rem;
        color: #8a8ace;
      }
    }
  }
}
</style>
