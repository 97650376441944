<template>
  <div ref="SwiperBox" id="SwiperBoxId">
    <div class="swiper-left">
      <div
        class="left-bg1 point"
       >
          <div class="tab-title" :class="{'tab-title-active':!isAllStatus}"  @click="changeStatusOnClick"> 实时</div>
          <div class="tab-title" :class="{'tab-title-active': isAllStatus}"  @click="changeStatusOnClick"> 欧皇</div>
        </div>
    </div>
    <NoticeBar
      class="noice-bar"
      speed="30"
      delay="0"
      scrollable
      color="#1989fa"
      background="#000"
      ref="NoticeBar">
      <div class="SwiperBox">
        <div
          v-for="(item, index) in carouselArr"
          :key="index"
          class="swiper-box-item">
          <!-- @click="swiperItemOnClick(item)" -->
          <div > 
            <div
              class="swiper-item"
              :class="
                item.lv != 0 ? `lv${item.lv}` : item.bean > 1 ? 'lv1' : 'lv4'
              "
              dir="ltr">
              <div class="batlle" v-show="item.type == 3">
                <!-- <img src="@/assets/newImages/battle.png" alt="" /> -->
              </div>
              <div class="batlle" v-show="item.type == 1">
                <!-- <img src="@/assets/newImages/openbox.png" alt="" /> -->
              </div>
              <div class="batlle" v-show="item.type == 4">
                <!-- <img src="@/assets/newImages/lucky.png" alt="" /> -->
              </div>
              <div class="batlle" v-show="item.type == 7">
                <!-- 熔炼 -->
                <!-- <img src="@/assets/newImages/ronglian.png" alt="" /> -->
              </div>
              <div class="batlle" v-show="item.type == 9">
                <!-- 弹药 -->
                <!-- <img src="@/assets/newImages/zidan.png" alt="" /> -->
              </div>
              <div class="left">
                <div
                  class="pic"
                  :style="{
                    'background-image': 'url(' + item.lv_bg_image != null + ')',
                  }">
                  <img :src="item.cover" alt="" />
                </div>
                <div class="data">
                  <span class="goodsname" dir="ltr">{{
                    item.name | GuoLvOne
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NoticeBar>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { UnpackingRecord } from "@/network/api.js";
import vueSeamlessScroll from "vue-seamless-scroll";
import { NoticeBar } from "vant";
export default {
  name: "SwiperBox",
  data() {
    return {
      isAllStatus: false,
      // 轮播上边
      SuiJiNum: 0,
      carouselArr: [],
      loadingShow: true,
      activeStatus: 2,
      swiperOption: {
        loop: true,
        // 自动轮播
        autoplay: {
          delay: 5000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false,
        },
        speed: 1000,
        simulateTouch: false,
        spaceBetween: 0,
        //开启循环模式
        // loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        centeredSlidesBounds: true,
      },
    };
  },

  filters: {
    LvGuoLv(val) {
      if (val == "1") {
        return "lv1";
      } else if (val == "2") {
        return "lv2";
      } else if (val == "3") {
        return "lv3";
      } else if (val == "4") {
        return "lv4";
      } else if (val == "5") {
        return "lv4";
      }
    },

    GuoLvOne(val) {
      return val.length > 12 ? val.substr(0, 10) + "..." : val;
    },
  },
  created() {
    this.GetUnpackingRecord();
    this.TenSeconds();
  },
  mounted() {
    let left = 0;
    setInterval(() => {
      left += 360;
      this.$refs.SwiperBox?.scrollTo({ left, behavior: "smooth" });
      if (this.$refs?.SwiperBox?.scrollTo > 2000) {
        this.$refs.SwiperBox.scrollTo({ left: 0 });
      }
    }, 300000);
  },

  methods: {
    changeStatusOnClick() {
      this.isAllStatus = !this.isAllStatus;
      this.GetUnpackingRecord();
    },
    swiperItemOnClick(item) {
      // 有8种可能，但是目前只用到了三种
      let routerUrl = "";
      if (item.type == "3") {
        routerUrl = "/Battle";
      } else if (item.type == "4") {
        if (item.bean > 1) {
          routerUrl = "/Lucky_open?id=" + item.skin_id;
        } else {
          routerUrl = "/Lucky";
        }
      } else if (item.type == "7") {
        routerUrl = "/fusion";
      } else if (item.type == "10") {
        routerUrl = `/OpeningBox?id=${item.box_id}&typeid=13`;
      } else {
        routerUrl = "/OpeningBox?id=" + item.box_id;
      }
      this.$router.push(routerUrl);
    },
    changeStatus(n) {
      this.activeStatus = n;
    },
    //开奖记录
    GetUnpackingRecord() {
      const params = {
        type: this.isAllStatus ? 2 : 1,
      };
      UnpackingRecord(params).then((res) => {
        this.carouselArr = res.data.data;
        if (this.$refs?.NoticeBar) {
          this.$refs.NoticeBar.reset();
        }
      });
    },
    TenSeconds() {
      if (this.Timer) {
        clearInterval(this.Timer);
      }
      this.Timer = setInterval(() => {
        setTimeout(() => {
          this.GetUnpackingRecord();
        }, 0);
      }, 60000);
    },
  },

  components: {
    Swiper,
    SwiperSlide,
    vueSeamlessScroll,
    NoticeBar,
  },
};
</script>

<style lang="scss">
#SwiperBoxId {
  width: 100%;
  margin-bottom: 0.08rem;
  position: relative;
  .swiper-left {
    top: 0;
    left: 0;
    width: 0.51rem;
    height: 100%;
    z-index: 2;
    flex-shrink: 0;
    position: absolute;
    .left-bg1 {
      position: absolute;
      top: -0.1rem;
      height: 0.46rem;
      width: 0.55rem;
      height: 0.98rem;
      text-align: center;
      background: url(../../../assets/newImages/nav-bg1.png) no-repeat
        center;
      background-size: 100% 100%;
    }
    .tab-title {
      line-height: 0.46rem;
      color: #745A31;
      font-weight: 400;
      font-size: 0.13rem;
    }
    .tab-title-active {
      color: #fff;
    }
  }
  .noice-bar {
    min-height: 0.8rem;
    padding: 0;
  }
  .SwiperBox {
    background: #0f2756;
    min-height: 0.8rem;
    display: flex;
    overflow-x: auto;
  }
}
.swiper-box-item {
  width: 0.8rem;
  min-height: 0.8rem;
  flex-shrink: 0;
  .swiper-item {
    width: 100%;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    color: #fff;
    height: 0.8rem;
    .batlle {
      width: 0.15rem;
      position: absolute;
      right: 0.05rem;
      top: 0.09rem;

      img {
        width: 80%;
      }
    }
    .left {
      // height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 0rem;
      box-sizing: border-box;
      justify-content: center;

      .pic {
        width: 0.7rem;
        height: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.2rem;
        img {
          width: 80%;
        }
      }
      .data {
        .goodsname {
          display: block;
          margin-top: 0.02rem;
          width: 70%;
          font-size: 0.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
