<template>
  <div class="record-mod2">
    <div class="center">
      <div class="tabbar-box">
        <div
          class="btn btn2"
          v-for="(item, index) in subMenu"
          :key="index"
          @click="subMenuClick(index)"
        >
          <span :style="item == chooseItem && 'color:#228ec8'">{{ item }}</span>
        </div>
        <!-- <div class="btn" @click.stop="tabChange('other')">
        <span>会员</span>
        <i class="icon" :class="otherStatus ? 'active' : ''"></i>
        <div class="drop" :class="otherStatus ? 'dropAni' : ''">
          <div class="drop-item" @click="dropItemClick('all')">会员</div>
          <div class="drop-item" @click="dropItemClick('all')">主播</div>
          <div class="drop-item" @click="dropItemClick('all')">机器人</div>
        </div>
      </div> -->
        <img
          src="../../../assets/images/classics/rest.png"
          alt=""
          class="rest"
          :class="restStatus ? 'restAni' : ''"
          @click="restChange"
          @tap="restChange"
        />
      </div>
      <div class="record-title">
        <div class="table-title">
          <div
            class="table-title-item"
            v-for="(t, i) in tableRecordList"
            :style="{ width: t.width, textAlign: t.center }"
            :key="i"
          >
            {{ t.title }}
          </div>
        </div>
        <div class="table-box">
          <div
            class="table-item"
            v-for="(item, i) in tableData.list"
            :key="i"
            :class="`table-item${i % 2 === 0 ? 0 : 1}`"
          >
            <div
              :class="j.index"
              v-for="(j, k) in tableRecordList"
              :key="k"
              :style="{ width: j.width, textAlign: j.center }"
            >
              <template v-if="!j.render">
                <div :style="j.style">
                  <img v-if="j.img" :src="j.img.index" alt="" />
                  <span v-if="j.leftVal">{{ j.leftVal }}</span>
                  {{ item[j.index] }}
                  <span v-if="j.rightVal">{{ j.rightVal }}</span>
                </div>
              </template>
              <template v-else>
                <slot :item="j.index" :data="item"></slot>
              </template>
            </div>
          </div>
        </div>
        <div class="headerTitle">
          <slot name="headerTitle"></slot>
        </div>
        <div class="footerContent">
          <slot name="footerContent"></slot>
        </div>
        <!-- <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
          :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next" :total="tableData.total">
        </el-pagination>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableRecordList: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Object,
      default: () => {},
    },
    chooseItem: {
      type: [String, Number],
      default: 100,
    },
    subMenu: {
      type: Array,
      default: () => ["100", "300", "500"],
    },
  },
  data() {
    return {
      allStatus: false,
      otherStatus: false,
      restStatus: false,
      currentPage: 1,
    };
  },
  mounted() {
    document.body.addEventListener("click", this.closeDrop);
  },
  destroyed() {
    document.body.removeEventListener("click", this.closeDrop);
  },
  methods: {
    subMenuClick(i) {
      this.$emit("subMenuClick", this.subMenu[i]);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    restChange() {
      this.restStatus = true;
      setTimeout(() => {
        this.restStatus = false;
      }, 1000);
    },
    dropItemClick(type) {
      this.$emit("dropItemClick", type);
    },
    closeDrop() {
      if (this.allStatus || this.otherStatus) {
        this.allStatus = false;
        this.otherStatus = false;
      }
    },
    tabChange(type) {
      if (type === "all") {
        this.allStatus = !this.allStatus;
        this.otherStatus = false;
      } else {
        this.otherStatus = !this.otherStatus;
        this.allStatus = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.record-mod2 {
  width: 100%;
  background: #05111b;
  box-sizing: border-box;
  // padding: 0.4rem 0.3rem;
  position: relative;

  .center {
    width: 3.2rem;
    margin: 0 auto;
  }

  .headerTitle {
    position: absolute;
    font-size: 0.16rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    right: 0.1rem;
  }

  .footerContent {
    font-size: 0.1rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #c1c1c1;
    margin-top: 0.5rem;
    // text-align: center;
  }

  .tabbar-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .btn {
      position: relative;
      width: 0.6rem;
      height: 0.2rem;
      line-height: 0.2rem;
      background: #000000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 0.2rem;
      box-shadow: inset 0px 0px 7px 3px #267b81;
      border-radius: 0.2rem 0 0.2rem 0;
      font-size: 0.1rem;
      cursor: pointer;

      i {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.08rem 0.1rem 0 0.1rem;
        border-color: #999999 transparent transparent transparent;
        transition: all 0.5s;
      }

      .active {
        transform: rotate(180deg);
      }

      .drop {
        display: none;
        position: absolute;
        bottom: -1.6rem;
        left: 0;
        width: 1.85rem;
        // height: 0.7rem;
        background: rgba(184, 178, 178, 0.9);
        box-shadow: 0px 1px 5px 1px #eee;
        box-sizing: border-box;
        padding: 15px 0;
        text-align: center;

        .drop-item {
          box-sizing: border-box;
          cursor: pointer;

          &:hover {
            background: rgb(120, 120, 120);
          }
        }
      }

      .dropAni {
        display: block;
        opacity: 0;
        animation: dropAni 2s forwards;
      }
    }

    .btn2 {
      justify-content: center;
    }

    .rest {
      width: 0.2rem;
      height: 0.2rem;
      margin-left: 1rem !important;
      cursor: pointer;
    }

    .restAni {
      animation: rotate 0.5s forwards;
    }
  }

  .record-title {
    width: 100%;
    margin-top: 10px;

    .table-title {
      width: 100%;
      height: 0.31rem;
      background: url(../../../assets/images/classics/table-title.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;

      .table-title-item {
        font-size: 0.12rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #cccccc;
      }
    }

    .table-box {
      width: 100%;

      .table-item {
        display: flex;
        height: 0.4rem;
        line-height: 0.4rem;
        background: #333333;
        box-sizing: border-box;
        font-size: 0.1rem;
        font-weight: 400;
        color: #cccccc;
      }

      .table-item0 {
        box-shadow: inset 0px 0px 10px 5px #33637b;
      }

      .table-item1 {
        box-shadow: inset 0px 0px 8px 2px #338dca;
      }
    }
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes dropAni {
  to {
    opacity: 1;
  }
}
</style>
